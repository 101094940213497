import { Link } from '@remix-run/react';
import { useState } from 'react';

import {
  type DtoImportPromptTemplateRequest,
  type DtoPromptTemplate,
  type ModelsSettingsPromptTemplateMappings,
} from '@lp-lib/api-service-client/public';

import { IconCopyButton } from '../components/common/CopyButton';
import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { useSettingsData } from '../components/DynamicSettings/hooks/useSettingsData';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { DownloadIcon } from '../components/icons/DownloadIcon';
import { PlusIcon } from '../components/icons/PlusIcon';
import { Loading } from '../components/Loading';
import { PromptTemplatePicker } from '../components/PromptTemplate/PromptTemplatePicker';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { useFeatureQueryParam } from '../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { useTitle } from '../hooks/useTitle';
import { safeWindowReload } from '../logger/logger';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import {
  downloadObjectAsJSON,
  err2s,
  makeTitle,
  randomString,
} from '../utils/common';

export function Component() {
  const { data, isLoading, error, updateValue } = useSettingsData(
    'prompt-template-mappings'
  );

  useTitle(makeTitle('Prompt Template Mappings'));

  const handleSave = useLiveCallback(
    async (mappings: ModelsSettingsPromptTemplateMappings['mappings']) => {
      await updateValue({
        key: 'mappings',
        value: mappings,
      });
    }
  );

  if (isLoading) {
    return (
      <div className='fixed inset-0 flex items-center justify-center bg-lp-black-001 z-50'>
        <Loading text='' />
      </div>
    );
  } else if (error || !data) {
    return (
      <div className='w-full h-full flex items-center justify-center text-red-002'>
        Error loading prompt template mappings
      </div>
    );
  }

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <MappingsTable mappings={data.mappings} onSave={handleSave} />
    </AdminView>
  );
}

function MappingEditor(props: {
  title: string;
  btnLabel: string;
  initial?: { key: string; templateId: string; description?: string | null };
  onCancel: () => void;
  onSave: (mapping: {
    key: string;
    templateId: string;
    description?: string | null;
  }) => void;
  error?: string;
}) {
  const [key, setKey] = useState(props.initial?.key ?? '');
  const [description, setDescription] = useState(
    props.initial?.description ?? ''
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<DtoPromptTemplate | null>(null);

  // Initialize with the initial template ID
  const isValid =
    key.trim() !== '' &&
    (selectedTemplate !== null || props.initial?.templateId !== undefined);

  const handleSave = useLiveCallback(async () => {
    if (!isValid) return;
    props.onSave({
      key: key.trim(),
      templateId: selectedTemplate?.id ?? props.initial?.templateId ?? '',
      description: description.trim() || null,
    });
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-180 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-2xl'>{props.title}</div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-4'>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Key</div>
              <div className='text-xs text-icon-gray'>
                Unique identifier for this mapping
              </div>
            </div>
            <input
              type='text'
              className={
                'w-full h-10 field mb-0' + (props.error ? ' field-error' : '')
              }
              autoComplete='off'
              placeholder='Enter key'
              value={key}
              onChange={(e) => setKey(e.target.value)}
              autoFocus
            />
          </div>

          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Template</div>
              <div className='text-xs text-icon-gray'>
                Select a prompt template
              </div>
            </div>
            <div className='flex flex-col gap-2'>
              <PromptTemplatePicker
                templateId={selectedTemplate?.id ?? props.initial?.templateId}
                onChange={setSelectedTemplate}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Description</div>
              <div className='text-xs text-icon-gray'>
                Optional description of this mapping
              </div>
            </div>
            <textarea
              className='w-full h-24 field mb-0 py-2'
              placeholder='Enter description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className='h-8 mb-4'>
          {props.error && (
            <div className='text-red-002 text-sm text-center'>
              {props.error}
            </div>
          )}
        </div>

        <div className='mt-auto w-full pb-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleSave}
            disabled={!isValid}
          >
            {props.btnLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

function AddMappingModalContent(props: {
  onCancel: () => void;
  onConfirm: () => void;
  mappings: ModelsSettingsPromptTemplateMappings['mappings'];
  onSave: (mappings: ModelsSettingsPromptTemplateMappings['mappings']) => void;
}) {
  const [error, setError] = useState<string>('');

  const handleSave = async (mapping: {
    key: string;
    templateId: string;
    description?: string | null;
  }) => {
    // Check if key already exists
    if (props.mappings[mapping.key]) {
      setError(
        'A mapping with this key already exists. Please choose a different key.'
      );
      return;
    }

    const newMappings = { ...props.mappings };
    newMappings[mapping.key] = {
      key: mapping.key,
      templateId: mapping.templateId,
      description: mapping.description,
    };
    props.onSave(newMappings);
    props.onConfirm();
  };

  return (
    <MappingEditor
      title='Add Mapping'
      btnLabel='Create'
      onCancel={props.onCancel}
      onSave={handleSave}
      error={error}
    />
  );
}

function EditMappingModalContent(props: {
  onCancel: () => void;
  onConfirm: () => void;
  mappings: ModelsSettingsPromptTemplateMappings['mappings'];
  onSave: (mappings: ModelsSettingsPromptTemplateMappings['mappings']) => void;
  currentKey: string;
  mapping: { key: string; templateId: string; description?: string | null };
}) {
  const [error, setError] = useState<string>('');

  const handleSave = async (newMapping: {
    key: string;
    templateId: string;
    description?: string | null;
  }) => {
    // Check if new key exists and it's not the current key being edited
    if (newMapping.key !== props.currentKey && props.mappings[newMapping.key]) {
      setError(
        'A mapping with this key already exists. Please choose a different key.'
      );
      return;
    }

    const newMappings = { ...props.mappings };
    if (props.currentKey !== newMapping.key) {
      delete newMappings[props.currentKey];
    }
    newMappings[newMapping.key] = {
      key: newMapping.key,
      templateId: newMapping.templateId,
      description: newMapping.description,
    };
    props.onSave(newMappings);
    props.onConfirm();
  };

  return (
    <MappingEditor
      title='Edit Mapping'
      btnLabel='Save'
      initial={props.mapping}
      onCancel={props.onCancel}
      onSave={handleSave}
      error={error}
    />
  );
}

function ExportModalContent(props: {
  onCancel: () => void;
  onConfirm: () => void;
  mappings: ModelsSettingsPromptTemplateMappings['mappings'];
}) {
  const [keyPrefix, setKeyPrefix] = useState('agent');

  const {
    call: handleExport,
    state: { state, error },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.promptTemplate.exportTemplates(keyPrefix);
    downloadObjectAsJSON(resp.data, `prompt-templates-for-${keyPrefix}`);
    props.onConfirm();
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-180 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-2xl'>
            Export Prompt Templates by Mapping Key Prefix
          </div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-4'>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Key</div>
              <div className='text-xs text-icon-gray'>
                Enter the key prefix to export prompt templates for
              </div>
            </div>
            <input
              type='text'
              className={
                'w-full h-10 field mb-0' + (error ? ' field-error' : '')
              }
              autoComplete='off'
              placeholder='Enter key prefix'
              value={keyPrefix}
              onChange={(e) => setKeyPrefix(e.target.value)}
              autoFocus
            />
          </div>
        </div>

        <div className='h-8 mb-4'>
          {error && (
            <div className='text-red-002 text-sm text-center'>
              {err2s(error)}
            </div>
          )}
        </div>

        <div className='mt-auto w-full pb-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleExport}
            disabled={state.isRunning}
          >
            Export
          </button>
        </div>
      </div>
    </div>
  );
}

function ImportModalContent(props: {
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const [fileKey, setFileKey] = useState(randomString(10));
  const [payload, setPayload] = useState<unknown>(null);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const data = event.target?.result;
      if (typeof data === 'string') {
        setPayload(JSON.parse(data));
      }
    });
    reader.readAsText(file);
  };

  const {
    state: { state, error },
    call: doImport,
  } = useLiveAsyncCall(async (req: DtoImportPromptTemplateRequest) => {
    await apiService.promptTemplate.importTemplates(req);
    return true;
  });

  const handleImport = async () => {
    if (!payload) return;
    // the backend will validate the payload
    const resp = await doImport(payload as never);
    if (!resp) return;
    setFileKey(randomString(10));
    safeWindowReload();
    props.onConfirm();
  };

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-180 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-2xl'>Import Prompt Templates</div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-4'>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>File</div>
              <div className='text-xs text-icon-gray'>
                Select a JSON file containing prompt templates
              </div>
            </div>
            <input
              type='file'
              key={fileKey}
              onChange={onFileChange}
              accept='.json'
            />
          </div>
        </div>

        <div className='h-8 mb-4'>
          {error && (
            <div className='text-red-002 text-sm text-center'>
              {err2s(error)}
            </div>
          )}
        </div>

        <div className='mt-auto w-full pb-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleImport}
            disabled={state.isRunning}
          >
            Import
          </button>
        </div>
      </div>
    </div>
  );
}

function MappingsTable(props: {
  mappings: ModelsSettingsPromptTemplateMappings['mappings'];
  onSave: (mappings: ModelsSettingsPromptTemplateMappings['mappings']) => void;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleAddMapping = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <AddMappingModalContent
          onCancel={p.internalOnCancel}
          onConfirm={p.internalOnConfirm}
          mappings={props.mappings}
          onSave={props.onSave}
        />
      ),
    });
  };

  const handleEditMapping = async (
    key: string,
    mapping: { key: string; templateId: string; description?: string | null }
  ) => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <EditMappingModalContent
          onCancel={p.internalOnCancel}
          onConfirm={p.internalOnConfirm}
          mappings={props.mappings}
          onSave={props.onSave}
          currentKey={key}
          mapping={mapping}
        />
      ),
    });
  };

  const handleDeleteMapping = async (key: string) => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-white text-center text-2xl font-medium'>
          Are you sure you want to delete this mapping?
        </div>
      ),
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    const newMappings = { ...props.mappings };
    delete newMappings[key];
    props.onSave(newMappings);
  };

  const handleExport = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ExportModalContent
          onCancel={p.internalOnCancel}
          onConfirm={p.internalOnConfirm}
          mappings={props.mappings}
        />
      ),
    });
  };

  const handleImport = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ImportModalContent
          onCancel={p.internalOnCancel}
          onConfirm={p.internalOnConfirm}
        />
      ),
    });
  };

  const superAdmin = useFeatureQueryParam('super-admin');

  return (
    <div className='text-white'>
      <div className='pb-4'>
        <div className='flex justify-between items-center'>
          <h2 className='text-2xl font-bold'>
            Prompt Template Mappings{' '}
            <span className='text-icon-gray text-xl'>
              ({Object.keys(props.mappings ?? {}).length})
            </span>
          </h2>
          <button
            type='button'
            className='btn flex justify-center items-center text-primary gap-1 text-sm hover:underline'
            onClick={handleAddMapping}
          >
            <PlusIcon />
            Add Mapping
          </button>
        </div>
        <div className='flex justify-between items-center'>
          <div className='text-sm text-icon-gray'>
            Map prompt templates to specific contexts
          </div>
          {superAdmin && (
            <div className='flex gap-2 items-center'>
              <button
                type='button'
                className='btn flex justify-center items-center text-secondary gap-1 text-sms hover:underline'
                onClick={handleExport}
              >
                <DownloadIcon className='w-3.5 h-3.5 fill-current' />
                Export
              </button>
              <button
                type='button'
                className='btn flex justify-center items-center text-secondary gap-1 text-sms hover:underline'
                onClick={handleImport}
              >
                <DownloadIcon className='w-3.5 h-3.5 fill-current rotate-180' />
                Import
              </button>
            </div>
          )}
        </div>
      </div>

      {!props.mappings || Object.keys(props.mappings).length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No mappings
        </div>
      ) : (
        <table className='w-full table-fixed border-collapse'>
          <thead>
            <tr>
              <th className='text-left font-bold pl-3 py-2 border-b border-t border-secondary w-1/4'>
                Key
              </th>
              <th className='text-left font-bold py-2 border-b border-t border-secondary w-1/4'>
                Template ID
              </th>
              <th className='text-left font-bold py-2 border-b border-t border-secondary'>
                Description
              </th>
              <th className='text-left font-bold py-2 border-b border-t border-secondary w-12'></th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(props.mappings).map(([key, mapping]) => (
              <tr key={key} className='hover:bg-dark-gray'>
                <td
                  className='text-left font-bold pl-3 py-3 border-b border-black-001 pr-3 truncate cursor-pointer'
                  onClick={() => handleEditMapping(key, mapping)}
                >
                  <div className='flex items-center gap-2'>
                    <span>{mapping.key}</span>
                    <div onClick={(e) => e.stopPropagation()}>
                      <IconCopyButton text={mapping.key} />
                    </div>
                  </div>
                </td>
                <td className='text-left py-3 border-b border-black-001 pr-3'>
                  <Link
                    to={`/admin/prompt-templates/${mapping.templateId}`}
                    className='text-primary hover:underline'
                    target='_blank'
                  >
                    {mapping.templateId}
                  </Link>
                </td>
                <td
                  className='text-left text-icon-gray py-3 border-b border-black-001 pr-3 truncate cursor-pointer'
                  onClick={() => handleEditMapping(key, mapping)}
                >
                  {mapping.description ?? ''}
                </td>
                <td className='h-px p-0 border-b border-black-001'>
                  <div className='h-full flex items-center justify-center'>
                    <button
                      type='button'
                      className='btn text-red-002'
                      onClick={() => handleDeleteMapping(key)}
                    >
                      <DeleteIcon className='w-3.5 h-3.5 fill-current' />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
